import "./Page2.css";
import Typewriter from "typewriter-effect";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	solid,
	regular,
	brands,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { useEffect, useRef, useState } from "react";
import MediaQuery from "react-responsive";

function Page2() {
	const el = useRef();
	const q = gsap.utils.selector(el);
	const tl = useRef();

	const [darkMode, setDarkMode] = useState(
		document.body.classList.contains("dark")
	);

	useEffect(() => {}, [darkMode]);

	gsap.registerPlugin(ScrollTrigger);

	function hide(elem) {
		gsap.set(elem, { autoAlpha: 0 });
	}
	function animateFrom(elem, direction) {
		elem.style.opacity = "0";
		gsap.fromTo(
			elem,
			{ autoAlpha: 0, y: 10 },
			{
				duration: 2,
				delay: 0.5,
				x: 0,
				y: 0,
				autoAlpha: 1,
				ease: "expo",
				overwrite: "auto",
			}
		);
	}
	const onMouseEnter = ({ currentTarget }) => {
		gsap.to(currentTarget, { scale: 1.1, opacity: 0.8 });
	};
	const onMouseLeave = ({ currentTarget }) => {
		gsap.to(currentTarget, { scale: 1, opacity: 0.5 });
	};
	// wait until DOM has been rendered
	useEffect(() => {
		gsap.utils.toArray(".reveal").forEach(function (elem) {
			hide(elem); // assure that the element is hidden when scrolled into view
		});
		ScrollTrigger.batch(".reveal", {
			interval: 1, // time window (in seconds) for batching to occur. The first callback that occurs (of its type) will start the timer, and when it elapses, any other similar callbacks for other targets will be batched into an array and fed to the callback. Default is 0.1
			batchMax: 2, // maximum batch size (targets)
			onEnter: (batch) =>
				gsap.fromTo(
					batch,
					{ y: 30 },
					{ autoAlpha: 1, y: 0, stagger: 0.45, overwrite: true }
				),
		});
	}, []);

	return (
		<div className="text-black dark:text-white flex flex-col items-center justify-center mt-10">
			<div className="flex text-4xl space-x-5 reveal font-bold">
				<h2> ~ About Me ~</h2>
			</div>
			<div className="reveal rounded-xl shadow-2xl mt-5 flex">
				<li>4o4</li>
			</div>
		</div>
	);
}

export default Page2;
