import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import ReactFullpage from "@fullpage/react-fullpage";
import "./index.css";
import "./output.css";
import Header from "./Header";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link,
	NavLink,
	useLocation,
	Navigate,
} from "react-router-dom";
import App from "./App";
import Page2 from "./Page2";
import Page3 from "./Page3";
import About from "./About";
import Pics from "./Pics";
import Blog from "./Blog";
import { ToastContainer, toast } from "react-toastify";
import { positions, Provider, transitions, types } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import reportWebVitals from "./reportWebVitals";
import Fade from "fade";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { EffectFade } from "swiper";
import { ReactFixedSticky, ReactTransformSticky } from "react-sticky-wrapper";
import "swiper/css";
import { render } from "@testing-library/react";

import { gsap, SteppedEase } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {
	BrowserView,
	MobileView,
	isBrowser,
	isMobile,
} from "react-device-detect";
import MediaQuery from "react-responsive";

gsap.registerPlugin(TextPlugin);
gsap.registerPlugin(ScrollTrigger);
const options = {
	timeout: 4000,
	offset: "calc(20px + 2vmin)",
	position: positions.MIDDLE,
	type: types.SUCCESS,
	transition: transitions.SCALE,
};

var carRef = React.createRef(); //create ref
const swipeRef = React.createRef();

const onLeave = function (origin, destination, direction) {};

function Main() {
	const location = useLocation();

	useEffect(() => {
		if (isMobile) {
			if (location.pathname == "/") {
				swipeRef.current.classList.remove("overflow-y-auto");
			} else {
				swipeRef.current.classList.add("overflow-y-auto");
			}
		}
	}, [location]);

	useEffect(() => {
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: "#para",
				start: "top top",
				end: "bottom top",
				scrub: true,
			},
		});

		gsap.utils.toArray(".parallax").forEach((layer) => {
			const depth = layer.dataset.depth;
			const movement = -(layer.offsetHeight * depth);
			tl.to(layer, { y: movement, ease: "none" }, 0);
		});
	}, []);

	return (
		<>
			<Header carRef={carRef} />

			<MediaQuery minWidth={1224}>
				<Routes className="invisible md:visible">
					<Route path="/" element={<Fullpage />} />
					<Route path="*" element={<Navigate replace to="/" />} />
				</Routes>
			</MediaQuery>
			<MediaQuery maxWidth={1224}>
				<Swiper
					className="md:invisible"
					spaceBetween={0}
					slidesPerView={"auto"}
					onSwiper={(swiper) => (fSwiper = swiper)}
					initialSlide={1}
					autoHeight={true}
					speed={500}
					resistanceRatio={0.85}
					ref={carRef}
				>
					<SwiperSlide className="md:max-w-8 w-2/3 md:w-1/6 flex flex-col min-h-screen space-y-5 items-center justify-center bg-stone-900 transition-colors duration-1000">
						<div className="w-full">
							<div className="h-24 w-full text-white text-center uppercase text-4xl justify-center flex bg-stone-700 self-center transition-colors duration-500 hover:bg-stone-300 hover:text-slate-900">
								<NavLink
									to="/"
									className="text-center mt-6 uppercase hover:text-slate-900"
									style={({ isActive }) =>
										isActive ? { color: `gold` } : {}
									}
									onClick={() => {
										fSwiper.slideNext();
									}}
								>
									main
								</NavLink>
							</div>
						</div>
					</SwiperSlide>

					<SwiperSlide
						className="text-black dark:text-white bg-white dark:bg-slate-900 transition-colors duration-1000 overflow-y-auto"
						ref={swipeRef}
					>
						<Routes>
							<Route path="/" element={<Fullpage />} />
							<Route
								path="*"
								element={<Navigate replace to="/" />}
							/>
						</Routes>
					</SwiperSlide>
				</Swiper>
			</MediaQuery>
		</>
	);
}

const Fullpage = () => (
	<div className="flex flex-col">
		<div id="para">
			<div
				className="layer-bg layer parallax bg-slate-900"
				data-depth="0.20"
			>
				<Provider template={AlertTemplate} {...options}></Provider>
			</div>
			<div
				className="layer parallax absolute top-32 left-1/4 md:left-1/3 text-white"
				data-depth="0.10"
			>
				<MediaQuery maxWidth={1224}>
					<img
						src="/ido9.jpg"
						className=" border-2 w-7/12 mb-5 rounded-b-lg parallax"
						data-depth="0.10"
						alt=""
					/>
					<div className="text-7xl uppercase">
						Idealcan <br /> Ayavefe
					</div>
				</MediaQuery>
				<MediaQuery minWidth={1224}>
					<img
						src="/ido9.jpg"
						className=" border-2 w-5/12 mb-5 md:w-1/5 rounded-b-lg parallax"
						data-depth="0.10"
						alt=""
					/>
					<div className="text-4xl ml-5 uppercase">
						personal website of
					</div>
					<div className="text-7xl uppercase">İdealcan Ayavefe</div>
				</MediaQuery>
			</div>
		</div>
		<div className="bg-white dark:bg-slate-900 transition-colors duration-1000">
			<Page2 />
		</div>
		<div className="bg-white dark:bg-slate-900 transition-colors duration-1000">
			<Page3 />
		</div>
	</div>
);

var fSwiper = "a";
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
	<Router className="">
		<Main />
	</Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
