import './Article.css';
import ReactTextTransition, { presets } from "react-text-transition";
import { Bounce, Fade, Roll } from "react-awesome-reveal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { gsap } from "gsap"
import React from 'react';
import { BrowserView, MobileOnlyView, MobileView } from 'react-device-detect';

class Article extends React.Component {
  constructor(props) {
    super(props);

  }

  onMouseEnter({currentTarget}){

    gsap.to(currentTarget, {x: -20, duration: 0.5})

  }

  onMouseLeave({currentTarget}){

    gsap.to(currentTarget, {x: 0, duration: 0.5})

  }
  
render(){
  return(
  <div className='flex flex-col items-center justify-center p-5 pt-12 md:pt-[25vh] '>
    <img alt='' src={this.props.a.image} className="rounded-lg w-fit max-h-[50vh] max-w-[50vh]"/>
    <div className='text-black dark:text-white mt-5 space-y-5 mx-auto text-left md:w-3/5'>
    
    <span className='text-lg'>{this.props.a.date}</span> <span className='rounded-lg bg-gradient-to-r from-blue-500 to-purple-500 p-1 text-white ml-5 mt-20 '>{this.props.a.category}</span>
      <h1 className='text-4xl md:text-5xl'>{this.props.a.title}</h1>
      <p className='tracking-wider
  first-letter:text-6xl first-letter:font-bold first-letter:text-black dark:first-letter:text-white
  first-letter:mr-3 first-letter:float-left whitespace-pre-line' dangerouslySetInnerHTML={{ __html: this.props.a.content} }></p>
  <FontAwesomeIcon icon={solid("long-arrow-left")} className="text-yellow-500 justify-end mr-5 hover:text-blue-500 text-5xl" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} onClick={this.props.f} />
    </div>
  </div>
  )
}

}



export default Article;
