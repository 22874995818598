import './Pics.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useAlert } from 'react-alert'
import { SocialIcon } from 'react-social-icons';
import { Nav, Navbar, Container, Row, Col} from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  browserHistory
} from "react-router-dom";
import { Link as Linke, animateScroll as scroll, animateScroll, scroller } from "react-scroll";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import { Pagination, Autoplay } from "swiper";

function Pics() {

  

  return (
    <div className="About bg-white dark:bg-slate-700 transition-colors duration-1000">
      <div className="App-header dark:text-white text-center">
      <Swiper
          centeredSlides={true}
          spaceBetween={0}
          effect={"coverflow"}
          slidesPerView={'auto'}
          slideToClickedSlide={true}
          grabCursor={true}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination ,Autoplay]}
          loop={true}
          resistanceRatio={0} className='w-full h-screen'>

<SwiperSlide className='flex justify-center align-middle'>
<img src='ido.jpeg' className=' object-cover'></img>
 
</SwiperSlide>

          </Swiper>
      </div>
    </div>
  );
}



export default Pics;
