import "./Page3.css";
import { SocialIcon } from "react-social-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { gsap } from "gsap";
import { JackInTheBox } from "react-awesome-reveal";
import { useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function Page3() {
	gsap.registerPlugin(ScrollTrigger);
	const la = useRef();
	const q = gsap.utils.selector(la);
	function hide(elem) {
		gsap.set(elem, { autoAlpha: 0 });
	}
	useEffect(() => {
		gsap.to(q(".eg"), {
			y: 5,
			repeat: -1,
			yoyo: true,
			yoyoEase: true,
			ease: "back",
			duration: 1,
		});
		gsap.to(q(".eg"), {
			scale: 1.1,
			repeat: -1,
			yoyo: true,
			yoyoEase: true,
			ease: "back",
			duration: 1,
		});
	}, []);

	useEffect(() => {
		gsap.utils.toArray(".reveal").forEach(function (elem) {
			hide(elem); // assure that the element is hidden when scrolled into view
		});
		ScrollTrigger.batch(".reveal", {
			interval: 0.5, // time window (in seconds) for batching to occur. The first callback that occurs (of its type) will start the timer, and when it elapses, any other similar callbacks for other targets will be batched into an array and fed to the callback. Default is 0.1
			batchMax: 4, // maximum batch size (targets)
			onEnter: (batch) =>
				gsap.fromTo(
					batch,
					{ y: 30 },
					{ autoAlpha: 1, y: 0, stagger: 0.25, overwrite: true }
				),
		});
	}, []);

	const onMouseEnter = ({ currentTarget }) => {
		gsap.to(currentTarget, { scale: 1.2 });
	};

	const onMouseLeave = ({ currentTarget }) => {
		gsap.to(currentTarget, { scale: 1 });
	};

	return (
		<div
			id="contactme"
			className="text-center my-20 text-black dark:text-white flex flex-col align-middle justify-center"
			ref={la}
		>
			<JackInTheBox triggerOnce>
				<h1 className="reveal text-5xl text-center">~ Contact Me ~</h1>
				<h2 className="reveal text-xl mt-5 md:text-2xl">
					Click on the pop-up to copy!
				</h2>
			</JackInTheBox>
			<div className="mt-8 flex items-center justify-center text-center">
				<div
					className="App-social reveal"
					onClick={() => {
						toast.clearWaitingQueue();
						toast.dismiss();
						toast("canefe0", {
							onClick: () => {
								toast.clearWaitingQueue();
								toast.dismiss();
								navigator.clipboard.writeText("canefe0");
								toast("Copied!");
							},
						});
					}}
				>
					<SocialIcon
						className="hover:bg-cyan-50 bg-white border-2 border-yellow-500 rounded-full hover:opacity-80 eg"
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
						network="snapchat"
					/>
				</div>
				<div
					className="App-social reveal"
					onClick={() => {
						toast.clearWaitingQueue();
						toast.dismiss();
						toast("im_canefe", {
							onClick: () => {
								toast.clearWaitingQueue();
								toast.dismiss();
								navigator.clipboard.writeText("im_canefe");
								toast("Copied!");
							},
						});
					}}
				>
					<SocialIcon
						className="hover:bg-cyan-50 bg-white border-2 border-pink-600 rounded-full hover:opacity-80 eg"
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
						network="instagram"
					/>
				</div>
				<div
					className="App-social reveal"
					onClick={() => {
						toast.clearWaitingQueue();
						toast.dismiss();
						toast("feralcat#8070", {
							onClick: () => {
								toast.clearWaitingQueue();
								toast.dismiss();
								navigator.clipboard.writeText("feralcat#8070");
								toast("Copied!");
							},
						});
					}}
				>
					<SocialIcon
						className="hover:bg-cyan-50 bg-white border-2 border-violet-600 rounded-full hover:opacity-80 eg"
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
						network="discord"
					/>
				</div>

				<div
					className="App-social reveal"
					onClick={() => {
						toast.clearWaitingQueue();
						toast.dismiss();
						toast("contact@idealcanayavefe.com", {
							onClick: () => {
								toast.clearWaitingQueue();
								toast.dismiss();
								navigator.clipboard.writeText(
									"contact@idealcanayavefe.com"
								);
								toast("Copied!");
							},
						});
					}}
				>
					<SocialIcon
						className="hover:bg-cyan-50 bg-white border-2 border-stone-500 rounded-full hover:opacity-80 eg"
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
						network="email"
					/>
				</div>
			</div>
		</div>
	);
}

export default Page3;
