import {React, useState, useEffect} from 'react';
import { ToastContainer } from 'react-toastify';
import { Swiper, SwiperSlide } from 'swiper/react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import {useLocation} from 'react-router-dom'
// Import Swiper styles
import 'swiper/css';
import Fade from "fade";
import gsap from "gsap";
import { NavLink } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import MediaQuery from 'react-responsive';

function ScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    }
  }, [scrollDirection]);

  return scrollDirection;
};

function Header(props) {

  const [la, setla] = useState(0)
  const [darkMode, setdarkMode] = useState(false)

  var a = 0

  const location = useLocation();

  const scrollDirection = ScrollDirection()

  const resetLa = () => {

  }
  
  
  const setThemeInStorage = (theme) => {
    localStorage.setItem('theme', theme)
  }
  const fu = () => {
    if (props.carRef.current && props.carRef.current.swiper){
      a=1
      props.carRef.current.swiper.on('slideChange', (swiper) => {
        if(la == 0){
          if (swiper.activeIndex == 0){
            setla(1)
          }
        }
        if(la == 1){
          if (swiper.activeIndex == 1){
            setla(0)
          }
        }
      })
    }else{

    }    
  }

  useEffect(() => {
    if (scrollDirection == "down"){
      gsap.to(document.getElementsByClassName("ls"), {opacity:0, duration: 0.5})
    }else{
      gsap.to(document.getElementsByClassName("ls"), {opacity:1, duration: 0.5})
    }
  }, [scrollDirection])

  useEffect(() => {

    if (localStorage.getItem('theme') === 'dark'){toggleDarkMode(true)}

  }, [])

  useEffect(() => {
    if (a == 0){
      fu()
    }
    gsap.to(document.getElementById("log"), {x:5, repeat: -1, duration:2, yoyo:true, yoyoEase:true})
  })

  const toggleDarkMode = (b) => {

    if(!document.body.classList.contains('dark')){
      console.log(`check if dark`)
      setdarkMode(true)
      document.body.classList.add('dark');
      localStorage.setItem('theme', 'dark')
    }else if(b!==false){
      setdarkMode(false)
      document.body.classList.remove('dark');
      localStorage.setItem('theme', 'light')
    }



  }


  return(
    <div>
<ToastContainer className="text-center text-black text-lg"
position="bottom-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
limit={1}
pauseOnFocusLoss
draggable
pauseOnHover
/>
    <div id="header" className="fixed block w-full z-20 hover:opacity-100 h-16 text-4xl font-display">
      <div className='text-black dark:text-white font-sans'>
      <MediaQuery minWidth={1224}>
      <div className="invisible max-h-0 md:visible flex space-x-10 m-10 text-2xl">

        <div className='!ml-auto mr-10 align-middle text-4xl'>
        {(localStorage.getItem('theme') == 'dark')
          ? <FontAwesomeIcon icon={solid("sun")} className="text-yellow-500 justify-end hover:text-yellow-300" onClick={toggleDarkMode}></FontAwesomeIcon>
          : <FontAwesomeIcon icon={solid("moon")} className="text-yellow-500 hover:text-yellow-300 justify-end" onClick={toggleDarkMode}></FontAwesomeIcon>
        }
        </div>
      </div>
      </MediaQuery>
      <div className='flex m-5 w-full md:invisible'><FontAwesomeIcon icon={(la == 0) ? solid('bars') : solid('x')} onClick={() => {
        if (la == 0){
          props.carRef.current.swiper.slideTo(0, 500)
          props.carRef.current.swiper.slideChange = () => { setla(0) }
          setla(1)
        }else{
          props.carRef.current.swiper.slideTo(1)
          props.carRef.current.swiper.slideChange = () => {  setla(1) }
          setla(0)
        }; 
        }}/>

        <div className='ml-auto mr-10 align-middle'>
        {(localStorage.getItem('theme') == 'dark')
          ? <FontAwesomeIcon icon={solid("sun")} className="text-yellow-500 justify-end" onClick={toggleDarkMode}></FontAwesomeIcon>
          : <FontAwesomeIcon icon={solid("moon")} className="text-yellow-500 justify-end" onClick={toggleDarkMode}></FontAwesomeIcon>
        }
        </div>
      </div>

      </div>

    </div>
    </div>
  )}

export default Header;
