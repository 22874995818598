import './Blog.css';
import React from 'react'
import Article from './Article.js';
import Articles from './articles.json'
import { gsap, SteppedEase } from "gsap";
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation
} from "react-router-dom";

class Blog extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = { main: true, articleKey: 0 };
    this.redirect = this.redirect.bind(this);
    this.goBack = this.goBack.bind(this);

    this.el = React.createRef();
    this.mai = React.createRef();
  }

  dollo({currentTarget}){
    gsap.to(currentTarget, {scale:1.05})
  }

  dollso({currentTarget}){
    gsap.to(currentTarget, {scale:1})
  }

  truncateString(yourString, maxLength) {
    // get the index of space after maxLength
    const index = yourString.indexOf(" ", maxLength);
    return index === -1 ? yourString : yourString.substring(0, index)
  }

  redirect(id){
    const les = this
    this.q = gsap.utils.selector(this.el);
    gsap.fromTo(this.q(".mai"), {opacity:1},{opacity:0, duration: 0.5, onComplete:function(){
      gsap.to(les.q(".mai"), {opacity:1})
      les.setState((state) => {
        return {main: false, articleKey: id};
      })
    }})

    
    setTimeout(() => {this.props.carRef.current.swiper.updateAutoHeight(500);}, 800);    
  }
  componentDidMount(){
    setTimeout(() => this.props.carRef.current.swiper.updateAutoHeight(500), 200);   
    this.q = gsap.utils.selector(this.el);
    gsap.fromTo(this.q(".mai"), {opacity:0},{opacity:1, duration: 1, delay:0.2})
  }  
  goBack(){
    const les = this
    gsap.fromTo(this.q(".artic"), {opacity:1},{opacity:0, duration: 0.5, onComplete:function(){

      les.setState((state) => {
        return {main: true};
      })
      setTimeout(() => {gsap.fromTo(les.q(".mai"), {opacity:0},{opacity:1, duration: 0.5})}, 1);   
    }})

    setTimeout(() => this.props.carRef.current.swiper.updateAutoHeight(500), 200);      
  }

render() {
  return (
    <div id="cont" className="App h-auto bg-white dark:bg-slate-800 transition-colors duration-1000 font-blog"  ref={this.el}>
      {
      (this.state.main == true) ?
        
      
        <div className="bg-white dark:bg-slate-800 h-full min-h-screen text-black dark:text-white text-left mai transition-colors duration-1000">   
        <h1 className='text-5xl pt-20 text-center'>Blog</h1>
        <section className="text-gray-600 body-font">
                  <div className="container px-5 py-24 mx-auto">
                    <div className="flex flex-wrap -m-4">
      {Articles.articles.slice().reverse().map((data, key) => {
        return (  

                      <section className="p-4 md:w-1/3 snap-x snap-start transition-colors duration-1000" key={key} onMouseEnter={this.dollo} onMouseLeave={this.dollso}>
                        <div className="h-full rounded-xl shadow-cla-blue bg-gradient-to-r text-black from-indigo-50 to-blue-50 dark:from-slate-900 dark:to-gray-900 dark:text-white overflow-hidden transition-colors duration-1000">
                          <img className=" lg:h-48 md:h-36 w-full object-cover object-center scale-110 transition-all duration-400 hover:scale-100 h-40" src={data.image} alt="blog"/>
                          <div className="p-6">
                            <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1 dark:text-indigo-20"><span>{data.date}</span> <span className='rounded-lg bg-gradient-to-r from-blue-500 to-purple-500 p-1 text-white ml-5'>{data.category}</span></h2>
                            <h1 className="title-font text-lg font-medium text-gray-600 mb-3 dark:text-white">{data.title}</h1>
                            <p className="leading-relaxed mb-3" dangerouslySetInnerHTML={{ __html: this.truncateString(data.content,100) + "..."} }></p>
                            <div className="flex items-center flex-wrap mt-10 ">
                              <button className="bg-gradient-to-r text-white from-cyan-400 to-blue-400 hover:scale-105 drop-shadow-md  shadow-cla-blue px-4 py-1 rounded-lg mx-auto md:ml-0" onClick={() => this.redirect(key)}>Read more</button>
                             
                            </div>
                          </div>
                        </div>
                      </section>

        )
      })}
                    </div>
                  </div>
                </section>
      </div>
      :
      <div className='bg-white dark:bg-slate-900 min-h-screen transition-colors duration-1000'>
        <div className='artic'>
        <Article a={Articles.articles.slice().reverse()[this.state.articleKey]} f={this.goBack}/>
        </div>

      </div>
      }
    </div>
    
  );
  }

}



export default Blog;
