import "./App.css";
import ReactTextTransition, { presets } from "react-text-transition";
import { useEffect, useRef, useState } from "react";
import { gsap, SteppedEase } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import {
	solid,
	regular,
	brands,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { text } from "@fortawesome/fontawesome-svg-core";
import MediaQuery from 'react-responsive'

gsap.registerPlugin(TextPlugin);

function TextBox(props) {
	return <div></div>;
}

function App() {
	const el = useRef();
	const q = gsap.utils.selector(el);
	const tl = useRef();
	const tl2 = useRef();
	const [Text, setText] = useState("Welcome.");

	// wait until DOM has been rendered
	useEffect(() => {
		tl.current = gsap
			.timeline()
			.to(q(".arro"), {
				duration: 1,
				opacity: 1,
			})
			.to(q(".arro"), {
				duration: 0.7,
				y: 25,
				repeat: -1,
				ease: "back",
				yoyo: true,
				yoyoEase: true,
			});
	}, []);

	return (
		<div className="App">
			<div className="App-header max-w-7xl mx-auto text-white">
				<div
					ref={el}
					className="w-full h-full flex flex-col items-center justify-center text-5xl"
				>

					<MediaQuery maxWidth={1224}>
						Idealcan <br /> Ayavefe
					</MediaQuery>
					<MediaQuery minWidth={1224}>
						<div className="text-7xl uppercase">İdealcan Ayavefe</div>
					</MediaQuery>
          

					<FontAwesomeIcon
						className="arro mt-5"
						icon={solid("arrow-down")}
					/>
				</div>
			</div>
		</div>
	);
}

export default App;
